(function () {
  'use strict';

  angular
    .module('neo.home.cancellations.canceledMatches.postponeCanceledMatches')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('home.cancellations.canceledMatches.postponeCanceledMatches', {
        url: '/{matchId}/postpone',
        templateUrl: 'home/postponements/new-postponement/new-postponement.tpl.html',
        controller: 'PostponeCanceledMatchesCtrl',
        controllerAs: 'vm',
        resolve: {
          facilities: function (Facilities) {
            return Facilities.query().$promise;
          },
          entities: function (Entitats) {
            return Entitats.query().$promise;
          },
          match: function (Matches, $stateParams) {
            return Matches.get({id: $stateParams.matchId}).$promise;
          }
        }
      });
  }
}());
